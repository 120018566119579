
import { Component, Vue } from "vue-property-decorator";

@Component
export default class LayoutNoToken extends Vue {
  get breakpointCheck(): boolean {
    if (!(this.$route.path === "/public/verifyEmailByLink")) {
      return this.$vuetify.breakpoint.mdAndUp;
    } else {
      return true;
    }
  }
}
